import { useState } from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function Download(props) {
    const { title, tint, style, file } = props

    return (
        <motion.div style={{ ...style, ...containerStyle }}>
            <motion.div
                onTap={() => {
                    const link = document.createElement("a")
                    link.href = file
                    link.download = "download"
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }}
                style={{
                    boxSizing: "border-box",
                    width: "260px" /* 206px */,
                    height: "60px" /* */,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "18px 32px 18px 32px",
                    backgroundColor: "#E40246",
                    overflow: "visible",
                    alignContent: "center",
                    flexWrap: "nowrap",
                    gap: 18,
                    borderRadius: 200,
                    fontFamily: "IBM Plex Sans Arabic Semi Bold",
                    fontSize: 18,
                    color: "white",
                }}
                whileHover={{
                    /*scale: 1.01,*/
                    backgroundColor: "#fd3571",
                    fontFamily: "IBM Plex Sans Arabic Semi Bold",
                }}
            >
                {title}
            </motion.div>
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
        </motion.div>
    )
}

Download.defaultProps = {
    tint: "#09F",
    title: "Download",
}

addPropertyControls(Download, {
    tint: {
        title: "Tint",
        type: ControlType.Color,
    },
    title: {
        title: "Label",
        type: ControlType.String,
    },
    file: {
        title: "File",
        type: ControlType.File,
        allowedFileTypes: [],
    },
})

const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}
